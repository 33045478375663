import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const IconBox = ({ icon, link }) => {
  return (
    <Box
      sx={{
        bgcolor: "#333",
        color: "#fff",
        height: "34px",
        width: "34px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        transition: "all 0.3s ease-out",
        cursor: "pointer",
        "&:hover": {
          bgcolor: "#079AB5",
          transform: "scale(1.1)",
        },
      }}
    >
      <Link
        to={link}
        style={{
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          fontSize: "14px",
        }}
        target="_blank"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& > svg": {
              fontSize: "18px",
            },
          }}
        >
          {icon}
        </Box>
      </Link>
    </Box>
  );
};

export default IconBox;
