import { Typography } from "@mui/material";
import React from "react";

const FormHeading = ({ text }) => {
  return (
    <Typography
      fontWeight={400}
      sx={{
        color: "white",
        fontSize: { xs: "24px", sm: "24px", md: "26px", lg: "34px" },
        textAlign: "center",
        fontFamily: "Outfit",
      }}
    >
      {text}
    </Typography>
  );
};

export default FormHeading;
