import { Box, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import { TrackCurrentQuestionNum } from "../../pages/onBoarding/FormPage/FormPage";
const InstructionBox = ({ img, stepNum }) => {
  const [instText, setInsText] = useState([]);
  const [currActiveQuesAtom, setcurrActiveQuesAtom] = useAtom(
    TrackCurrentQuestionNum
  );
  console.log("currActiveQuesAtom", currActiveQuesAtom);
  useEffect(() => {
    if (currActiveQuesAtom === 1.1) {
      setInsText([
        " The statement should be precise, defined, actionable, shows habits & values, and specific.",
      ]);
    } else if (currActiveQuesAtom === 1.2) {
      setInsText([
        " From where do you mostly seek the answers? Is it Google? Blogs? Books? YouTube?",
      ]);
    } else if (currActiveQuesAtom === 2.1) {
      setInsText([
        " Provide a concise description of the notable difficulties and hurdles you are currently dealing with in your personal or professional life.",
      ]);
    } else if (currActiveQuesAtom === 2.2) {
      setInsText([
        " Describe specific thoughts or situations that make you feel deeply afraid or anxious.",
      ]);
    } else if (currActiveQuesAtom === 3.1) {
      setInsText([" Paint your dream picture!"]);
    } else if (currActiveQuesAtom === 3.2) {
      setInsText([" Email? Text? Chat? Video call? Via LinkedIn?"]);
    } else if (currActiveQuesAtom === 4.1) {
      setInsText([
        " Describe your industry and niche concisely, highlighting key characteristics, target audience, and unique selling points that set your business apart",
      ]);
    } else if (currActiveQuesAtom === 4.2) {
      setInsText([
        " Share the most common problems, complaints, and phrases you have heard or encountered related to this niche.",
      ]);
    } else if (currActiveQuesAtom === 5.1) {
      setInsText([" Describe what brings you happiness and joy in life"]);
    } else if (currActiveQuesAtom === 5.2) {
      setInsText([
        " From the moment you wake up until you go to bed, share the activities, experiences, and moments that would make your day absolutely perfect.",
      ]);
    } else {
      setInsText([""]);
    }
  }, [currActiveQuesAtom]);

  return (
    // <Box
    //   className="instruction-box"
    //   sx={{
    //     height: "100%",
    //     width: "100%",
    //     // background: "#464646",
    //     bgcolor: "#252525",
    //     backgroundImage: `url(${img})`,
    //     backgroundSize: "100% 100%",
    //     objectFit: "cover",
    //     backgroundRepeat: "no-repeat",
    //     borderTopLeftRadius: "10px",
    //     borderTopRightRadius: "10px",
    //     transition: currActiveQuesAtom > 0 && "all 1s ease-in-out",
    //   }}
    // >
    //   {currActiveQuesAtom > 0 && (
    //     <Box
    //       sx={{
    //         height: "100%",
    //         px: { xs: "10px", md: "60px" },
    //         display: "flex",
    //         alignItems: "center",
    //         flexDirection: "row",
    //         justifyContent: { xs: "center", md: "flex-start" },
    //       }}
    //     >
    //       <Box
    //         textAlign={"start"}
    //         fontWeight={400}
    //         sx={{
    //           color: "#fff",
    //           zIndex: 33,
    //           fontSize: {
    //             xs: "13px",
    //             sm: "14px",
    //             md: "16px",
    //             lg: "17px",
    //             xl: "19px",
    //           },
    //           "& * span": {
    //             fontSize: {
    //               xs: "12px",
    //               sm: "14px",
    //               md: "14px",
    //               lg: "16px",
    //               xl: "18px",
    //             },
    //           },
    //         }}
    //       >
    //         <span
    //           style={{
    //             color: "#fff",
    //             marginRight: "2px",
    //             fontWeight: 600,
    //           }}
    //         >
    //           Instructions:
    //         </span>
    //         <Typewriter
    //           key={instText.join("")}
    //           loop={1}
    //           typeSpeed={35}
    //           deleteSpeed={70}
    //           delaySpeed={1000}
    //           words={instText}
    //         />
    //       </Box>
    //     </Box>
    //   )}
    // </Box>
    <Box
      className="instruction-box"
      sx={{
        height: "100%",
        width: "100%",
        // background: "#464646",
        bgcolor: "#252525",
        // backgroundImage: `url(${img})`,
        backgroundSize: "100% 100%",
        objectFit: "cover",
        backgroundRepeat: "no-repeat",
        borderTopLeftRadius: "10px",
        position: "relative",
        borderTopRightRadius: "10px",
        // filter: currActiveQuesAtom > 0 && "blur(5px)",
        // transition: currActiveQuesAtom > 0 && "all 1s ease-in-out",
        zIndex: 2,
        opacity: 0.8,
        position: "relative",
      }}
    >
      <>
        <Box
          component="img"
          src={img}
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            filter: currActiveQuesAtom > 0 && "blur(3px)",
            backdropFilter: currActiveQuesAtom > 0 && "blur(5px)",
            transition: currActiveQuesAtom > 0 && "all 1s ease-in-out",
            opacity: currActiveQuesAtom > 0 ? 0.3 : 1,
          }}
        />
        {currActiveQuesAtom > 0 && (
          <Box
            sx={{
              zIndex: 9999,
              height: "100%",
              px: { xs: "10px", md: "60px" },
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <Box
              textAlign={"start"}
              fontWeight={400}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                mx: "20px",
                mt: "45px",
                color: "#fff",
                // zIndex: 9999,
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "16px",
                  lg: "17px",
                  xl: "19px",
                },
                "& * span": {
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    lg: "16px",
                    xl: "18px",
                  },
                },
              }}
            >
              <span
                style={{
                  color: "#fff",
                  marginRight: "2px",
                  fontWeight: 600,
                }}
              >
                Instructions:
              </span>
              <Typewriter
                key={instText.join("")}
                loop={1}
                typeSpeed={35}
                deleteSpeed={70}
                delaySpeed={1000}
                words={instText}
              />
            </Box>
          </Box>
        )}
      </>
    </Box>
  );
};

export default InstructionBox;
