import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import image from "../../../Assets/images/artboard-6.webp";
import successAlert from "../../../Assets/images/success.webp";
import failAlert from "../../../Assets/images/failed.webp";
import Dropzone from "react-dropzone";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import CircularProgressBar from "../../../components/CircularProgressBar/CircularProgressBar";
import Swal from "sweetalert2";
import { TrackCurrentStepNum } from "../FormPage/FormPage";
import { useAtom, atom } from "jotai";
import { userDataAtom } from "../Home/Home";
import { useNavigate } from "react-router-dom";
import { storage } from "../../../firebase";
import { TrackCurrentPage } from "../index";
import dayjs from "dayjs";

export const videoUploadAtom = atom(false);
export const videoUploadProgressAtom = atom(0);

export default function RecordOrUploadVideoForm() {
  const [currentPage, setcurrentPage] = useAtom(TrackCurrentPage);

  const [userInfo, setuserInfo] = useAtom(userDataAtom);
  const { homeC, ...UserData } = userInfo;
  console.log(UserData);
  const {
    homeA,
    homeB,
    homeD,
    oneA,
    oneB,
    twoA,
    twoB,
    threeA,
    threeB,
    fourA,
    fourB,
    fiveA,
    fiveB,
  } = userInfo;
  console.log(homeA, homeB, homeC, homeD);
  const details = {
    homeA: { Q: "Email", A: homeA },
    homeB: { Q: "Project Name", A: homeB },
    // homeC: { Q: "Date", A: homeC },
    homeD: { Q: "Name", A: homeD },
    oneA: { Q: "Where do you hang out?", A: oneA },
    oneB: {
      Q: "Where do you go to get the desired information?",
      A: oneB,
    },
    twoA: {
      Q: "What are your biggest frustrations and challenges?",
      A: twoA,
    },
    twoB: { Q: "What is your biggest fear?", A: twoB },
    threeA: {
      Q: "How you will define your dreams, hopes and desires?",
      A: threeA,
    },
    threeB: {
      Q: "What is your preferred form of communication?",
      A: threeB,
    },
    fourA: {
      Q: "How you will define your industry and specific niche?",
      A: fourA,
    },
    fourB: {
      Q: "What are the most common problems or phrases you heard about this niche?",
      A: fourB,
    },
    fiveA: { Q: "What makes you happy?", A: fiveA },
    fiveB: { Q: "What does your ideal day look like?", A: fiveB },
  };
  console.log(details);
  const data = {
    ...userInfo,
  };
  console.log(data);
  const navigate = useNavigate();
  const [currActiveStepAtom, setcurrActiveStepAtom] =
    useAtom(TrackCurrentStepNum);
  console.log("currActiveQuesAtom", currActiveStepAtom);
  const [videoUploadLoading, setvideoUploadLoading] = useAtom(videoUploadAtom);
  const [videoUploadPercentage, setvideoUploadPercentage] = useAtom(
    videoUploadProgressAtom
  );
  // let videoDuration = useRef(null);

  const formattedDate = dayjs(userInfo?.homeC)?.format("DD/MM/YYYY");

  const uploadUserDataToFirebase = async (url) => {
    // setuserInfo({
    //   ...userInfo,
    //   video: url,
    // });
    const bodyToSend = {
      // ...UserData,
      // homeC: formattedDate,
      ...details,
      homeC: { Question: "Date Of Birth", Answer: formattedDate },
      videoUrl: url,
    };

    const res = await fetch(
      "https://onboardingapp-12504-default-rtdb.firebaseio.com/OnBoardingUsers.json",
      {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(bodyToSend),
      }
    );
  };

  const uploadVideoToFirebase = async (filtToUpload) => {
    setvideoUploadLoading(true);
    console.log("filtToUpload: ", filtToUpload);
    const videoRef = ref(
      storage,
      `OnBoardingClientVideos/${filtToUpload?.name + Date.now()}`
    );
    console.log("videoRef: ", videoRef);
    const uploadTask = uploadBytesResumable(videoRef, filtToUpload);
    console.log("uploadTask", uploadTask);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload progress: ", Math.round(progress) + "%");
        setvideoUploadPercentage(Math.round(progress));
      },
      (err) => {
        console.log("firebase err", err);
      },
      async () => {
        console.log("Upload complete");

        try {
          const downloadURL = await getDownloadURL(
            uploadTask.snapshot.ref
          ).then((url) => {
            setvideoUploadLoading(false);
            console.log("Video download URL: ", url);
            Swal.fire({
              imageUrl: successAlert,
              allowOutsideClick: false,
              imageHeight: 200,
              title: "Uploaded successfully",
              text: "",
              color: "white",
              background: "#252525",
              confirmButtonColor: "#079AB5",
              // icon: "success",
              confirmButtonText: "Continue",
              allowEscapeKey: false,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Swal.fire("Saved!", "", "success");
                // navigate("/final");
                setcurrentPage((step) => step + 1);
              }
            });
            uploadUserDataToFirebase(url);
          });
          // Use the downloadURL as needed (e.g., store in state or perform further actions)
        } catch (error) {
          console.log("Error getting download URL: ", error);
          Swal.fire({
            imageUrl: failAlert,
            allowOutsideClick: false,
            imageHeight: 200,
            title: "Uploading failed",
            text: "",
            background: "#252525",
            showCancelButton: true,
            color: "white",
            // icon: "success",
            confirmButtonText: "Re-upload",
            confirmButtonColor: "transparent",
            cancelButtonText: "Record",
            cancelButtonColor: "#FF4747",
            allowEscapeKey: false,
          });
          // Handle the error if needed
        }
      }
    );
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#252525",
          height: "100%",
          borderRadius: "10px ",
          width: "70%",
          alignSelf: "center",
          "@media(max-width:900px)": { width: "100%" },
        }}
      >
        <Box
          sx={{
            height: {
              xs: "100px",
              sm: "125px",
              md: "130px",
              lg: "150px",
              xl: "",
            },
          }}
        >
          <img
            style={{
              objectFit: "fill",
              objectPosition: "center",
              borderRadius: "10px 10px 0px 0",
            }}
            src={image}
            alt={"imageSwap"}
            height={"100%"}
            width={"100%"}
          />
        </Box>
        {/* <img style={{ objectFit: "contain" }} src={image} alt={"imageSwap"} /> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              margin: "2%",
            }}
          >
            <Typography
              fontWeight={200}
              textAlign={"start"}
              sx={{
                color: "#24B2C5",
                fontWeight: "600",
              }}
            >
              Visualize Your Dream Project
            </Typography>
            <Typography
              fontWeight={200}
              sx={{
                color: "white",
                fontWeight: "400",
                textAlign: "start",
                marginBottom: "1%",
              }}
            >
              Just 60 sec video for better experience
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "5%",
              }}
            >
              <Dropzone
                accept={{
                  "video/*": [".mp4", ".mov", ".avi", ".mkv", ".webm"],
                }}
                onDrop={(acceptedFiles) => {
                  console.log("acceptedFiles", acceptedFiles);
                  uploadVideoToFirebase(acceptedFiles[0]);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    sx={{
                      flexWrap: "wrap",
                      rowGap: "10px",
                      padding: "15%",
                      borderRadius: "20px",
                      border: " 2px dashed #4F4F4F",
                      display: "flex",
                      justifyContent: "space-evenly",
                      // bgcolor: "red",
                    }}
                  >
                    <section {...getRootProps()}>
                      <input {...getInputProps()} />

                      <Button
                        variant="contained"
                        startIcon={<FileUploadIcon />}
                        sx={{
                          borderRadius: "50px",
                          bgcolor: "#24B2C5",
                          "&:hover": { bgcolor: "#24B2C5" },
                        }}
                      >
                        Upload
                      </Button>
                    </section>
                    <Button
                      onClick={() => {
                        console.log("record button", currActiveStepAtom);
                        setcurrActiveStepAtom((currValue) => currValue + 1);
                      }}
                      sx={{
                        borderRadius: "50px",
                        bgcolor: "#FF4747",
                        "&:hover": { bgcolor: "#FF4747" },
                      }}
                      variant="contained"
                      startIcon={<FiberManualRecordIcon />}
                    >
                      Record
                    </Button>
                  </Box>
                )}
              </Dropzone>
              <Typography
                fontWeight={200}
                sx={{
                  color: "#959595",
                  fontWeight: "400",
                  textAlign: "center",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                Share the details, inspiration, and vision of your idea in a
                video—whether recorded or uploaded. Trust us to connect the dots
                and reveal the big picture to you.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: "3%" }}>
          <Button
            variant="contained"
            sx={{
              width: "fit-content",
              alignSelf: "flex-end",
              bgcolor: "transparent",
              "&:hover": { bgcolor: "transparent" },
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "12px",
                lg: "14px",
                xl: "16px",
              },
            }}
            onClick={() => {
              setcurrActiveStepAtom((step) => step - 1);
            }}
          >
            Previous
          </Button>
        </Box>
      </Box>
      {videoUploadLoading && (
        <CircularProgressBar percentage={videoUploadPercentage} />
      )}
    </>
  );
}
