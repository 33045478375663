import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Banner from "./components/Banner/Banner";
import Projects from "./components/Projects/Projects";
import Footer from "./components/footer/Footer";
import ScrollTopBtn from "./components/ScrollTopBtn/ScrollTopBtn";
import OnBoarding from "./pages/onBoarding";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ScrollTopBtn />
          <BrowserRouter>
            <Navbar />
            <Banner />
            <OnBoarding />
            <Projects />
            <Footer />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
