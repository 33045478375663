import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  SxProps,
  Divider,
} from "@mui/material";
import image from "../../../Assets/images/artboard-1.webp";
import { useAtom, atom } from "jotai";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../../Utils";
import { TrackCurrentPage } from "../index";
import {
  DatePicker,
  DesktopDatePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import FormHeading from "../../../components/common/FormHeading/FormHeading";
import MainBox from "../../../components/common/MainBox";
export const existingUser = atom(null);
export const userDataAtom = atom({
  homeA: "",
  homeB: "",
  homeC: dayjs("17-07-2023").format("DD/MM/YYYY"),
  homeD: "",
  oneA: "",
  oneB: "",
  twoA: "",
  twoB: "",
  threeA: "",
  threeB: "",
  fourA: "",
  fourB: "",
  fiveA: "",
  fiveB: "",
  videoUrl: "",
});
function Home(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPage, setcurrentPage] = useAtom(TrackCurrentPage);
  const [userInfo, setuserInfo] = useAtom(userDataAtom);
  const [fieldRequiredError, setfieldRequiredError] = useState(false);
  const [inputRequiredError, setInputRequiredError] = useState({
    answer1: false,
    answer2: false,
  });
  console.log("homec", userInfo?.homeC);

  useEffect(() => {
    if (userInfo?.homeC === "Invalid Date" || userInfo?.homeD === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [userInfo]);
  return (
    <>
      <MainBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: "2%",
          }}
        >
          <FormHeading text={"Intake Form"} />
        </Box>
        <Divider sx={{ bgcolor: "#fff", mb: "3%" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#252525",
            borderRadius: "10px 10px 10px 10px",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              height: {
                xs: "120px",
                sm: "125px",
                md: "130px",
                lg: "150px",
                xl: "170px",
              },
            }}
          >
            <img
              style={{
                objectFit: "fill",
                objectPosition: "center",
                borderRadius: "10px 10px 0px 0",
              }}
              src={image}
              alt={"imageSwap"}
              height={"100%"}
              width={"100%"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: { xs: "wrap", md: "nowrap" },
              padding: "5%",
              columnGap: "2rem",
            }}
          >
            <Box
              sx={{
                // width: "90%",
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
                margin: "1%",
                flexGrow: 1,
                width: { xs: "200px", md: "300px" },
              }}
            >
              <Typography
                fontWeight={200}
                textAlign={"start"}
                sx={{
                  color: "#00BDDF",
                  fontWeight: "600",
                  fontSize: {
                    xs: "17px",
                    sm: "17px",
                    md: "18px",
                    lg: "22px",
                    xl: "25px",
                  },
                  marginLeft: "1.2%",
                }}
              >
                Your Name
              </Typography>
              <Box
                sx={{
                  bgcolor: "#3C3C3C",
                  borderRadius: "10px",
                  padding: { xs: "20px", md: "30px" },
                  // padding: "1.8rem",
                }}
              >
                <Typography
                  fontWeight={200}
                  textAlign={"start"}
                  sx={{
                    color: "white",
                    fontWeight: "400",
                    marginBottom: "1%",
                    marginLeft: "1%",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "14px",
                      lg: "16px",
                      xl: "18px",
                    },
                  }}
                >
                  {/* Enter your business email we will if already have your form
                  filled */}
                  Enter your name
                </Typography>
                <TextField
                  id="filled-basic"
                  multiline={true}
                  maxRows={3}
                  placeholder="John Doe"
                  variant="filled"
                  // autoComplete="off"
                  sx={{
                    width: "100%",
                    textarea: {
                      "::placeholder": { color: "#FFFFFF" },
                      color: "white",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "17px",
                      },
                    },
                  }}
                  value={userInfo?.homeD}
                  onChange={(value) =>
                    setuserInfo({
                      ...userInfo,
                      homeD: value.target.value,
                    })
                  }
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "transparent",
                      marginLeft: "0rem",
                      marginRight: "0rem",
                      marginTop: "0rem",
                    },
                  }}
                  inputProps={{
                    onBlur: () => {
                      setInputRequiredError((prevState) => ({
                        ...prevState,
                        answer1: true,
                      }));
                    },
                  }}
                  helperText={
                    inputRequiredError.answer1 && userInfo?.homeD == ""
                      ? "This field is required"
                      : ""
                  }
                  error={
                    inputRequiredError.answer1 && userInfo?.homeD == ""
                      ? true
                      : false
                  }
                />
              </Box>
            </Box>

            <Box
              sx={{
                // width: "90%",
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
                margin: "1%",
                flexGrow: 1,
                width: { xs: "200px", md: "300px" },
              }}
            >
              <Typography
                fontWeight={200}
                textAlign={"start"}
                sx={{
                  color: "#00BDDF",
                  fontWeight: "600",
                  fontSize: {
                    xs: "17px",
                    sm: "17px",
                    md: "18px",
                    lg: "22px",
                    xl: "25px",
                  },
                  marginLeft: "1.2%",
                }}
              >
                Date of Birth
              </Typography>
              <Box
                sx={{
                  bgcolor: "#3C3C3C",
                  borderRadius: "10px",
                  padding: { xs: "20px", md: "30px" },
                  color: "#fff",
                }}
              >
                <Typography
                  fontWeight={200}
                  textAlign={"start"}
                  sx={{
                    color: "white",
                    fontWeight: "400",
                    marginBottom: "1%",
                    marginLeft: "1%",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "14px",
                      lg: "16px",
                      xl: "18px",
                    },
                  }}
                >
                  Add DOB
                </Typography>
                <DatePicker
                  // defaultValue={"DD/MM/YYYY"}
                  // defaultValue={dayjs(userInfo?.homeC).format("DD/MM/YYYY")}
                  // value={dayjs(userInfo?.homeC).format("DD/MM/YYYY")}
                  value={userInfo?.homeC}
                  onChange={(e) =>
                    setuserInfo({
                      ...userInfo,
                      homeC: dayjs(e),
                    })
                  }
                  disableTextInput
                  maxDate={dayjs()}
                  disableFuture={true}
                  slotProps={{
                    textField: {
                      readOnly: true,
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      color:
                        userInfo?.homeC === "Invalid Date"
                          ? "#FDFDFD80"
                          : "#fff",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "17px",
                      },
                      backgroundColor: "#444",
                      ":hover": {
                        border: "none",
                        outline: "none",
                      },
                      outline: "none",
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderBottom: "1px solid #222",
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#ffff",
                    },
                    "& .MuiPickersDay-daySelected": {
                      backgroundColor: "#ff0000",
                      color: "#ffffff",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Button
            disabled={isDisabled}
            onClick={() => {
              if (!userInfo?.homeD) {
                setfieldRequiredError(true);
              } else if (userInfo?.homeD) {
                console.log("first time", currentPage);
                setcurrentPage((step) => step + 1);
              }
            }}
            variant="contained"
            sx={{
              width: "fit-content",
              alignSelf: "flex-end",
              m: "3%",
              color: "#fff",
              cursor: isDisabled ? "not-allowed" : "pointer",
              bgcolor: isDisabled ? "#5A5A5A" : "#079AB5",
              "&.Mui-disabled": {
                background: "#5A5A5A",
                color: "#ffff",
              },
              "&:hover": { bgcolor: isDisabled ? "#5A5A5A" : "#079AB5" },
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "12px",
                lg: "14px",
                xl: "16px",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </MainBox>
    </>
  );
}

export default Home;
