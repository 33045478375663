import {
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../Assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Close, Menu } from "@mui/icons-material";

const Navbar = () => {
  const NavBtn = styled(Box)(({ theme }) => ({
    // fontSize: "14px",
    cursor: "pointer",
    fontWeight: 400,
    backgroundColor: "#079AB5",
    borderRadius: "100px",
    [theme.breakpoints.up("lg")]: {
      padding: "6px 24px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "13px",
      padding: "7px 24px",
      "& > a": {
        fontSize: "13px",
      },
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
      padding: "10px 24px",
      "& > a": {
        fontSize: "13px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "7px 24px",
    },
  }));

  const [showBlur, setShowBlur] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [showCaseLink, setShowCaseLink] = useState(false);
  const [showBox, setShowBox] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 50) {
        setShowBlur(true);
      } else {
        setShowBlur(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLink = () => {
    setTimeout(() => {
      setShowCaseLink(false);
    }, 3000);
  };

  const handleBox = () => {
    setTimeout(() => {
      setShowBox(false);
    }, 3000);
  };
  return (
    <Box
      sx={{
        position: "sticky",
        top: "0",
        left: "0",
        height: { xs: "48px", sm: "70px", md: "60px", xl: "67px" },
        width: "100%",
        backgroundColor: showBlur ? "rgba(0, 0, 0, 0.2)" : "transparent",
        backdropFilter: showBlur ? "saturate(180%) blur(10px)" : "none",
        WebkitBackdropFilter: showBlur ? "saturate(180%) blur(10px)" : "none",
        zIndex: "999",
        transition: "all 0.3s ease-out",
      }}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={"10px 24px"}
          height={"100%"}
          sx={{
            p: {
              xs: "10px 20px",
              sm: "12px 30px",
              md: "10px 20px",
              lg: "12px 23px",
              xl: "6px 22px",
            },
          }}
        >
          <Link to="https://tezeract.ai/">
            <Box
              sx={{
                height: {
                  xs: "24px",
                  sm: "50px",
                  md: "33px",
                  lg: "37px",
                  xl: "50px",
                },
              }}
            >
              <img src={logo} alt="logo" height={"100%"} />
            </Box>
          </Link>

          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"20px"}
            sx={{
              "&  a": {
                textDecoration: "none",
                color: "#fff",
                fontSize: "15px",
              },
              display: { xs: "none", md: "flex" },
            }}
          >
            <Link to="https://tezeract.ai/">Home</Link>
            <Link to="https://tezeract.ai/teams-leading-ai-companies/">
              Team
            </Link>
            <Link to="https://tezeract.ai/about-us/">About Us</Link>
            <Link to="https://tezeract.ai/tezeracts-ai-solutions/">
              Services
            </Link>
            <Box
              sx={{
                "& > a": {
                  textDecoration: "none",
                  color: "#fff",
                  fontSize: "15px",
                },
                position: "relative",
              }}
            >
              <Link
                onMouseEnter={() => setShowCaseLink(true)}
                onMouseLeave={handleLink}
                to="https://tezeract.ai/portfolio-ai-projects/"
              >
                Cases
              </Link>
              {(showCaseLink || showBox) && (
                <Box
                  onMouseLeave={handleBox}
                  onMouseEnter={() => setShowBox(true)}
                  sx={{
                    position: "absolute",
                    top: "30px",
                    borderRadius: "3px",
                    bgcolor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                    gap: "15px",
                    width: "140px",
                    py: "10px",
                    "& > a": {
                      textAlign: "start",
                      textDecoration: "none",
                      color: "#333",
                      fontSize: "13px",
                      pl: "30px",
                      transition: "all 0.3s ease-out",
                      "&:hover": {
                        color: "#079AB5",
                      },
                    },
                  }}
                >
                  <Link to="https://tezeract.ai/peersuma-automated-video-editing-tool-case-study/">
                    Peersuma
                  </Link>
                  <Link to="https://tezeract.ai/alisia-ocr-detection-system/">
                    Alisia
                  </Link>
                  <Link to="https://tezeract.ai/photo-retouch-advance-ai-photo-enhancer-tool/">
                    Photo retouch
                  </Link>
                  <Link to="https://tezeract.ai/formole-computer-vision-solution/">
                    FormOle
                  </Link>
                  <Link to="https://tezeract.ai/photosthetic-advance-photo-editing-application/">
                    Photosthetic
                  </Link>
                  <Link to="https://tezeract.ai/navex-ai-tracking-system/">
                    Navex
                  </Link>
                  <Link to="https://tezeract.ai/spin-tip-advanced-video-analytics-system/">
                    Spin Tip
                  </Link>
                  <Link to="https://tezeract.ai/ai-motion-dance-using-pose-estimation-techniques/">
                    Dance Motion
                  </Link>
                </Box>
              )}
            </Box>
            <Link to="https://tezeract.ai/tezeracts-blogs/">Blogs</Link>
            <Link to="https://tezeract.ai/contact-tezeract/">Contact Us</Link>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ gap: { xs: "10px", lg: "30px" } }}
          >
            <Box
              sx={{
                height: "22px",
                width: "22px",
                bgcolor: "#079AB5",
                borderRadius: "3px",
                color: "#fff",
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  // setShowCaseLink(false);
                  setShowNav(!showNav);
                }}
                sx={{ color: "#fff", "& > svg": { fontSize: "18px" } }}
              >
                {showNav ? <Close /> : <Menu />}
              </IconButton>
            </Box>
            <NavBtn
              sx={{
                "& > a": {
                  textDecoration: "none",
                  color: "white",
                  fontSize: {
                    xs: "12px",
                    md: "14px",
                    lg: "14px",
                  },
                },
              }}
            >
              <Link
                to={
                  "https://calendly.com/abdulhannan-tezeract/30min?month=2023-06"
                }
              >
                Let's Talk
              </Link>
            </NavBtn>
          </Stack>
        </Stack>

        {/* For Responsive */}
        <Box
          sx={{
            bgcolor: "#fff",
            width: "100%",
            maxHeight: showNav ? "700px" : "0px",
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            transition: "all 0.5s ease-out",
            overflow: "hidden",
            borderRadius: "6px",
            gap: "2px",
            "& > a": {
              textAlign: "start",
              textDecoration: "none",
              color: "#333",
              fontSize: "14px",
              p: "5px",
              pl: "14px",
              transition: "all 0.3s ease-out",
              "&:hover": {
                color: "#079AB5",
              },
            },
          }}
        >
          <Link to="https://tezeract.ai/">Home</Link>
          <Link to="https://tezeract.ai/teams-leading-ai-companies/">Team</Link>
          <Link to="https://tezeract.ai/about-us/">About Us</Link>
          <Link to="https://tezeract.ai/tezeracts-ai-solutions/">Services</Link>
          <Box
            onClick={() => setShowCaseLink(true)}
            sx={{
              "& > a": {
                textDecoration: "none",
                color: showCaseLink ? "#079AB5" : "#333",
                fontSize: "14px",
                p: "5px",
                pl: "14px",
              },
            }}
          >
            <Link
              //   onMouseLeave={() => setShowCaseLink(false)}
              to={
                showCaseLink ? "https://tezeract.ai/portfolio-ai-projects/" : ""
              }
            >
              Cases
            </Link>

            {/* {showCaseLink && ( */}

            <Collapse in={showCaseLink} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  py: "10px",
                  "& > a": {
                    textAlign: "start",
                    textDecoration: "none",
                    color: "#333",
                    fontSize: "13px",
                    pl: "30px",
                    transition: "all 0.3s ease-out",
                    "&:hover": {
                      color: "#079AB5",
                    },
                  },
                }}
              >
                <Link to="https://tezeract.ai/peersuma-automated-video-editing-tool-case-study/">
                  Peersuma
                </Link>
                <Link to="https://tezeract.ai/alisia-ocr-detection-system/">
                  Alisia
                </Link>
                <Link to="https://tezeract.ai/photo-retouch-advance-ai-photo-enhancer-tool/">
                  Photo retouch
                </Link>
                <Link to="https://tezeract.ai/formole-computer-vision-solution/">
                  FormOle
                </Link>
                <Link to="https://tezeract.ai/photosthetic-advance-photo-editing-application/">
                  Photosthetic
                </Link>
                <Link to="https://tezeract.ai/navex-ai-tracking-system/">
                  Navex
                </Link>
                <Link to="https://tezeract.ai/spin-tip-advanced-video-analytics-system/">
                  Spin Tip
                </Link>
                <Link to="https://tezeract.ai/ai-motion-dance-using-pose-estimation-techniques/">
                  Dance Motion
                </Link>
              </Box>
            </Collapse>
          </Box>
          <Link to="https://tezeract.ai/tezeracts-blogs/">Blogs</Link>
          <Link to="https://tezeract.ai/contact-tezeract/">Contact Us</Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
