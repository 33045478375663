import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import StepperDot from "./StepperDot";
import StepperHeading from "./StepperHeading";
import { useAtom, atom } from "jotai";
import {
  TrackCurrentQuestionNum,
  TrackCurrentStepNum,
} from "../../pages/onBoarding/FormPage/FormPage";

export default function Stepper() {
  const [currActiveQuesAtom, setcurrActiveQuesAtom] = useAtom(
    TrackCurrentQuestionNum
  );
  const [currActiveStepAtom, setcurrActiveStepAtom] =
    useAtom(TrackCurrentStepNum);
  console.log("currActiveQuesAtom", currActiveQuesAtom, currActiveStepAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        // height: "50%",
        // width: "20%",
        alignSelf: "flex-start",
        columnGap: "30px",
        "@media(max-width:900px)": { display: "none" },
      }}
    >
      <Box
        sx={{
          bgcolor: "#414141",
          //   width: "15%",
          borderRadius: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <StepperDot
          activeQuestionStatus={
            currActiveQuesAtom === 1.1
              ? "activeFirstDot"
              : currActiveQuesAtom === 1.2
              ? "activeSecondDot"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 1 ? "activeStep" : "DisableStep"
          }
          stepNum={1}
        />
        <StepperDot
          activeQuestionStatus={
            currActiveQuesAtom === 2.1
              ? "activeFirstDot"
              : currActiveQuesAtom === 2.2
              ? "activeSecondDot"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 2 ? "activeStep" : "DisableStep"
          }
          stepNum={2}
        />
        <StepperDot
          activeQuestionStatus={
            currActiveQuesAtom === 3.1
              ? "activeFirstDot"
              : currActiveQuesAtom === 3.2
              ? "activeSecondDot"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 3 ? "activeStep" : "DisableStep"
          }
          stepNum={3}
        />
        <StepperDot
          activeQuestionStatus={
            currActiveQuesAtom === 4.1
              ? "activeFirstDot"
              : currActiveQuesAtom === 4.2
              ? "activeSecondDot"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 4 ? "activeStep" : "DisableStep"
          }
          stepNum={4}
        />
        <StepperDot
          activeQuestionStatus={
            currActiveQuesAtom === 5.1
              ? "activeFirstDot"
              : currActiveQuesAtom === 5.2
              ? "activeSecondDot"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 5 ? "activeStep" : "DisableStep"
          }
          stepNum={5}
        />

        <StepperDot
          stepNum={6}
          activeStepStatus={
            currActiveStepAtom === 6 ? "activeStep" : "DisableStep"
          }
        />
      </Box>
      <Box
        sx={{
          // flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          textAlign: "center",
          // mt: currActiveStepAtom === 1 ? 1.5 : "",
        }}
      >
        <StepperHeading
          mainHeading={"Personal Information"}
          subHeading={["Social Circle", "Sources"]}
          stepNumber={1}
          activeSubItemStatus={
            currActiveQuesAtom === 1.1
              ? "activeFirstSubItem"
              : currActiveQuesAtom === 1.2
              ? "activeSecondSubItem"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 1 ? "activeStep" : "DisableStep"
          }
        />
        <StepperHeading
          mainHeading={"Understanding your challenges"}
          subHeading={["Problems", "Concerns"]}
          stepNumber={2}
          activeSubItemStatus={
            currActiveQuesAtom === 2.1
              ? "activeFirstSubItem"
              : currActiveQuesAtom === 2.2
              ? "activeSecondSubItem"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 2 ? "activeStep" : "DisableStep"
          }
        />
        <StepperHeading
          mainHeading={"Grasping your vision"}
          subHeading={["Dreams", "Preferences"]}
          stepNumber={3}
          activeSubItemStatus={
            currActiveQuesAtom === 3.1
              ? "activeFirstSubItem"
              : currActiveQuesAtom === 3.2
              ? "activeSecondSubItem"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 3 ? "activeStep" : "DisableStep"
          }
        />
        <StepperHeading
          mainHeading={" Industry Perspectives"}
          subHeading={["Industry Focus", "Niche Issues"]}
          stepNumber={4}
          activeSubItemStatus={
            currActiveQuesAtom === 4.1
              ? "activeFirstSubItem"
              : currActiveQuesAtom === 4.2
              ? "activeSecondSubItem"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 4 ? "activeStep" : "DisableStep"
          }
        />
        <StepperHeading
          mainHeading={"Exploring your desires"}
          subHeading={["Ideal Day", "Source of happiness"]}
          stepNumber={5}
          activeSubItemStatus={
            currActiveQuesAtom === 5.1
              ? "activeFirstSubItem"
              : currActiveQuesAtom === 5.2
              ? "activeSecondSubItem"
              : null
          }
          activeStepStatus={
            currActiveStepAtom === 5 ? "activeStep" : "DisableStep"
          }
        />
        <Box
          sx={{
            width: "100%",
            height: "44px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "start",
            fontSize: {
              xs: "12px",
              sm: "14px",
              md: "14px",
              lg: "16px",
              xl: "17px",
            },
          }}
        >
          <div style={{ color: "white", fontWeight: "600" }}>
            Video feedback
          </div>
        </Box>
      </Box>
    </Box>
  );
}
