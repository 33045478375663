import React, { useEffect, useRef, useState } from "react";

const Camera = () => {
  const videoRef = useRef(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [progress, setProgress] = useState(0);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    let localMediaRecorder = null;

    const startCamera = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play();
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };

    startCamera();

    return () => {
      if (localMediaRecorder) {
        localMediaRecorder.stop();
      }
    };
  }, []);

  const handleStartRecording = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const chunks = [];
      const options = { mimeType: "video/webm; codecs=vp9" };

      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      setMediaRecorder(mediaRecorder);
      setRecordedChunks([]);
      setProgress(0);
      setRecording(true);

      mediaRecorder.start();
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const handleRetake = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
    setMediaRecorder(null);
    setRecordedChunks([]);
    setProgress(0);
    setVideoUrl(null);
  };

  const handleDone = () => {
    if (recordedChunks.length > 0) {
      const videoBlob = new Blob(recordedChunks, { type: "video/webm" });
      const videoUrl = URL.createObjectURL(videoBlob);
      setVideoUrl(videoUrl);
    }
  };

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.onstop = () => {
        const recordedVideo = new Blob(recordedChunks, { type: "video/webm" });
        setRecordedChunks([]);
        setMediaRecorder(null);
        setProgress(0);

        const videoUrl = URL.createObjectURL(recordedVideo);
        setVideoUrl(videoUrl);
      };

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          setRecordedChunks((prevChunks) => [...prevChunks, e.data]);
        }
      };

      mediaRecorder.start();
      setProgress(0);

      const timerId = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 1);
      }, 1000);

      return () => {
        clearInterval(timerId);
      };
    }
  }, [mediaRecorder]);

  return (
    <div>
      <div>
        {videoUrl ? (
          <video src={videoUrl} controls />
        ) : (
          <video ref={videoRef} width="640" height="480" autoPlay muted />
        )}
        <div>Recording progress: {progress} seconds</div>
      </div>
      <div>
        {recording ? (
          <button onClick={handleStopRecording}>Stop</button>
        ) : (
          <button onClick={handleStartRecording}>Start Recording</button>
        )}
        {recordedChunks.length > 0 && (
          <React.Fragment>
            <button onClick={handleRetake}>Retake</button>
            <button onClick={handleDone}>Done</button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Camera;
