import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import { Collapse } from "react-collapse";

export default function StepperDot({
  stepNum,
  currQues,
  activeQuestionStatus,
  activeStepStatus,
}) {
  console.log("stepperDot props", stepNum, currQues);
  const [first, setfirst] = useState(false);
  return (
    <>
      <Box
        onClick={() => setfirst(!first)}
        sx={{
          bgcolor: activeStepStatus === "activeStep" ? "#24B2C5" : "#636363",
          // width: "44px",
          // height: "44px",
          width: { xs: "30px", sm: "32px", md: "40px", lg: "44px" },
          height: { xs: "30px", sm: "32px", md: "40px", lg: "44px" },
          borderRadius: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: stepNum == 6 ? "0px" : "15px",
          mt: stepNum == 1 ? "0px" : "15px",
          fontSize: {
            xs: "10px",
            sm: "12px",
            md: "13px",
            lg: "14px",
            xl: "16px",
          },
        }}
      >
        <div style={{ color: "white", fontWeight: "600" }}>{stepNum}</div>
      </Box>
      {stepNum != 6 && (
        <Collapse isOpened={activeStepStatus === "activeStep" ? true : false}>
          <Box
            sx={{
              //   bgcolor: "orange",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "80px",
              // height: stepNum === 5 ? "20px" : "",
              // rowGap: { md: "40px", sm: "40px", lg: "20px" },
              gap: { md: "10px", sm: "10px", lg: "10px" },
            }}
          >
            {stepNum != 6 && (
              <Box
                sx={{
                  bgcolor:
                    activeQuestionStatus === "activeFirstDot"
                      ? "white"
                      : "#797979",
                  width: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  height: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  borderRadius: "100px",
                }}
              ></Box>
            )}
            {stepNum != 6 && (
              <Box
                sx={{
                  bgcolor:
                    activeQuestionStatus === "activeSecondDot"
                      ? "white"
                      : "#797979",
                  width: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  height: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  borderRadius: "100px",
                }}
              ></Box>
            )}
          </Box>
        </Collapse>
      )}
    </>
  );
}
