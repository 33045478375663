import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../Wrapper/Wrapper";
import gradientBg from "../../Assets/images/gradient.png";
import { Link } from "react-router-dom";
import {
  ArrowForward,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from "@mui/icons-material";
import FooterLinks from "./footerLinks";
import IconBox from "../common/IconBox/IconBox";
import { Typewriter } from "react-simple-typewriter";

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          background: {
            xs: "radial-gradient(ellipse at top center, #00292F 20%, #000000 50%)",
            md: "radial-gradient(circle at top center, #00292F 20%, #000000 50%)",
          },
          px: { xs: "8px", md: "20px" },
          "& *": {
            fontFamily: "Outfit",
          },
        }}
      >
        <Wrapper>
          <Box sx={{ pt: "5%" }}>
            <Box
              sx={{
                backgroundImage: `url(${gradientBg})`,
                objectFit: "cover",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                opacity: "0.8",
                height: { md: "110px" },
                backgroundPosition: "center left",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-around",
                px: { xs: "8px", md: "0px" },
                alignItems: "center",
                color: "#fff",
                flexWrap: "wrap",
                py: { xs: "30px", md: "10px" },
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "16px", sm: "18px", md: "22px", lg: "22px" },
                  textAlign: { xs: "center", md: "start" },
                  maxWidth: {
                    xs: "100%",
                    sm: "450px",
                    md: "620px",
                    lg: "650px",
                  },
                }}
              >
                We{" "}
                <strong style={{ fontWeight: 700 }}>
                  <Typewriter
                    // key={instText.join("")}
                    loop={false}
                    typeSpeed={120}
                    deleteSpeed={120}
                    delaySpeed={2000}
                    words={["Develop", "Deploy", "Design"]}
                  />{" "}
                </strong>
                top-class AI products, solutions and services around the globe
              </Typography>
              <Box
                sx={{
                  background: "linear-gradient( 218deg, #850473 ,#6A5901)",
                  color: "#fff",
                  textTransform: "capitalize",
                  width: { xs: "120px", md: "160px" },
                  py: { xs: "10px", md: "12px" },
                  fontWeight: 500,
                  fontSize: { xs: "16px", md: "19px" },
                  textAlign: "center",
                  borderRadius: "4px",
                  transition: "transform .3s ease-out",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.1)",
                    background: "linear-gradient(220deg, #A38905, #BB07A2)",
                  },
                }}
              >
                <Link
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                  to={
                    "https://calendly.com/abdulhannan-tezeract/30min?month=2023-06"
                  }
                >
                  Contact Us
                </Link>
              </Box>
            </Box>
          </Box>
          {/* Footer Links */}
          <FooterLinks />
        </Wrapper>
      </Box>
      <Wrapper>
        <Divider
          variant="fullWidth"
          sx={{ bgcolor: "gray", maxWidth: "1140px" }}
        />
      </Wrapper>
      <Wrapper>
        <Stack
          sx={{
            color: "#7A7A7A",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center",
            gap: { xs: "20px", md: "" },
            px: { xs: "12px", md: "20px", lg: "15px" },
            py: { xs: "4%", md: "2%" },
            mb: "0.5%",
          }}
          // my={"2%"}
        >
          <Typography fontSize={"14px"}>All Rights Reserved. 2023</Typography>
          <Stack direction={"row"} gap={"10px"}>
            <IconBox
              icon={<LinkedIn />}
              link={"https://www.linkedin.com/company/tezeract/"}
            />
            <IconBox
              icon={<Facebook />}
              link={"https://www.facebook.com/Tezeractai"}
            />
            <IconBox
              icon={<Instagram />}
              link={"https://www.instagram.com/tezeract.ai/"}
            />
            <IconBox
              icon={<Twitter />}
              link={"https://twitter.com/tezeract_ai"}
            />
          </Stack>
        </Stack>
      </Wrapper>
    </>
  );
};

export default Footer;
