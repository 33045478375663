import { Box, Typography } from "@mui/material";
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = ({ percentage }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        zIndex: 999,
      }}
    >
      <div
        style={{
          width: "40%",
          maxWidth: "170px",
        }}
      >
        <CircularProgressbar value={percentage} text={`${percentage}%`} />
      </div>
      <Typography
        sx={{
          color: "#079AB5",
          marginTop: "1%",
          textAlign: "center",
          fontSize: { xs: "16px", sm: "18px", md: "18px" },
          ml: "20px",
        }}
      >
        Uploading Video...
      </Typography>
    </div>
  );
};

export default CircularProgressBar;
