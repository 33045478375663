import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  Divider,
} from "@mui/material";
import image1 from "../../../Assets/images/artboard-1.webp";
import image2 from "../../../Assets/images/artboard-2.webp";
import image3 from "../../../Assets/images/artboard-3.webp";
import image4 from "../../../Assets/images/artboard-4.webp";
import image5 from "../../../Assets/images/artboard-5.webp";
import image6 from "../../../Assets/images/artboard-7.webp";

import { useAtom, atom } from "jotai";
import { userDataAtom } from "../Home/Home";
import FormWithImage from "../../../components/FormWithImage/FormWithImage";
import RecordOrUploadVideoForm, {
  videoUploadAtom,
  videoUploadProgressAtom,
} from "../RecordOrUploadVideoForm/RecordOrUploadVideoForm";
import CameraScreen from "../CameraScreen/CameraScreen";
import Stepper from "../../../components/Stepper/Stepper";
import CircularProgressBar from "../../../components/CircularProgressBar/CircularProgressBar";
import FormHeading from "../../../components/common/FormHeading/FormHeading";
import MainBox from "../../../components/common/MainBox";

export const TrackCurrentQuestionNum = atom(0);
export const TrackCurrentStepNum = atom(1);

function FormPage(props) {
  const [videoUpload, setVideoUpload] = useAtom(videoUploadAtom);
  const [videoUploadProgress, setVideoUploadProgress] = useAtom(
    videoUploadProgressAtom
  );
  const [currentStep, setcurrentStep] = useAtom(TrackCurrentStepNum);
  const [userInfo, setuserInfo] = useAtom(userDataAtom);

  useEffect(() => {
    console.log("userInfo: ", userInfo);
  }, [userInfo]);

  const renderCurrentForm = () => {
    console.log("renderCurrentForm", currentStep);
    switch (currentStep) {
      case 1:
        return (
          <FormWithImage
            mainHeading={"Personal Information"}
            Question1={"Where do you hang out?"}
            Question2={"Where do you go to get the desired information?"}
            stepNum={1}
            setcurrentStep={setcurrentStep}
            artboardImage={image2}
            setuserInfo={setuserInfo}
            answer1={userInfo?.oneA}
            answer2={userInfo?.oneB}
          />
        );
      case 2:
        return (
          <FormWithImage
            mainHeading={"Understanding your challenges"}
            Question1={"What are your biggest frustrations and challenges?"}
            Question2={"What is your biggest fear?"}
            stepNum={2}
            setcurrentStep={setcurrentStep}
            artboardImage={image3}
            setuserInfo={setuserInfo}
            answer1={userInfo?.twoA}
            answer2={userInfo?.twoB}
          />
        );
      case 3:
        return (
          <FormWithImage
            mainHeading={"Grasping your vision"}
            Question1={"How you will define your dreams, hopes, and desires?"}
            Question2={"What is your preferred form of communication?"}
            stepNum={3}
            setcurrentStep={setcurrentStep}
            artboardImage={image4}
            setuserInfo={setuserInfo}
            answer1={userInfo?.threeA}
            answer2={userInfo?.threeB}
          />
        );
      case 4:
        return (
          <FormWithImage
            mainHeading={"Industry Perspectives"}
            Question1={"How you will define your industry and specific niche?"}
            Question2={
              "What are the most common problems or phrases you heard about this niche?"
            }
            stepNum={4}
            setcurrentStep={setcurrentStep}
            artboardImage={image5}
            setuserInfo={setuserInfo}
            answer1={userInfo?.fourA}
            answer2={userInfo?.fourB}
          />
        );
      case 5:
        return (
          <FormWithImage
            mainHeading={"Exploring your desires"}
            Question1={"What makes you happy?"}
            Question2={"What does your ideal day look like?"}
            stepNum={5}
            setcurrentStep={setcurrentStep}
            artboardImage={image6}
            setuserInfo={setuserInfo}
            answer1={userInfo?.fiveA}
            answer2={userInfo?.fiveB}
          />
        );
      case 6:
        return <RecordOrUploadVideoForm />;
      case 7:
        return <CameraScreen />;
      default:
        return null;
    }
  };

  return (
    <>
      <MainBox>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "4px",
            my: "2%",
            flexWrap: "wrap",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <FormHeading text={"Intake Form"} />
          <Box
            sx={{
              color: "#00F0FF",
              fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
            }}
          >
            <Typography className="progress">
              On-boarding In Progress
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ bgcolor: "#fff", mb: "4%" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // bgcolor: "#252525",
            // height: "80vh",
            width: "90%",
            alignSelf: "center",
            columnGap: "30px",
          }}
        >
          <Stepper />
          {renderCurrentForm()}
        </Box>
        {/* {videoUpload && (
          // <Box
          //   sx={{
          //     position: "absolute",
          //     top: "50%",
          //     left: "50%",
          //     transform: "translate(-50%,-50%)",
          //     width: "100%",
          //     height: "100%",
          //     backgroundColor: "rgba(0, 0, 0, 0.9)",
          //     zIndex: 2,
          //   }}
          // >
          <CircularProgressBar percentage={videoUploadProgress} />
          // </Box>
        )} */}
      </MainBox>
    </>
  );
}

export default FormPage;
