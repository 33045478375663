import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../Wrapper/Wrapper";
import bannerImage from "../../Assets/images/banner.jpg";

const Banner = () => {
  return (
    <Box
      sx={{
        "& *": {
          fontFamily: "Outfit",
        },
      }}
    >
      <Wrapper>
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          height={"100%"}
          my={"10%"}
          sx={{
            color: "#fff",
            px: { xs: "20px", lg: "0px" },
            gap: { xs: 3, md: 1 },
            "& *": {
              fontFamily: "Outfit",
            },
          }}
        >
          <Grid item xs={12} md={5} gap={2}>
            <Typography sx={{ fontSize: "34px", fontWeight: 700, mb: "1%" }}>
              Empowering your business through automation
            </Typography>
            <Typography
              sx={{ color: "#CCCCCC", fontSize: "15px", fontFamily: "Outfit" }}
            >
              Welcome to our Client Onboarding Page, We are thrilled to have you
              join our esteemed community of partners and clients and embark on
              a transformative journey together. Through our streamlined
              onboarding process, we ensure a seamless transition and effective
              collaboration. Together, we will uncover your unique goals,
              understand your business details, and tailor our solutions to meet
              your specific needs. Let’s start this exciting adventure together!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <img
                src={bannerImage}
                alt="bannerImage"
                width={"100%"}
                // height={"90%"}
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                  // height: "200px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
    </Box>
  );
};

export default Banner;
