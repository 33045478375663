import { Box, Button, Typography } from "@mui/material";
import React from "react";
import projectBg from "../../Assets/images/projects.png";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${projectBg})`,
        height: "420px",
        width: "100%",
        objectFit: "cover",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        "& *": {
          fontFamily: "Outfit",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "850px",
          textAlign: "center",
          gap: "20px",
          px: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "26px",
              sm: "22px",
              md: "25px",
              lg: "32px",
              xl: "35px",
            },
            fontWeight: 600,
            fontFamily: "Outfit",
          }}
        >
          Discover how we have turned ideas into reality
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: "14px",
              sm: "16px",
              md: "18px",
              lg: "20px",
              xl: "20px",
            },
          }}
        >
          With our top-graded AI services, we’ve empowered our partners and
          clients to accomplish their goals by transforming their ideas from
          concept to successful implementation.
        </Typography>
        <Box
          sx={{
            background: "linear-gradient(230deg, #676767 0%, #0097A4 100%)",
            color: "#fff",
            textTransform: "capitalize",
            fontSize: { xs: "16px", md: "19px" },
            fontWeight: 400,
            width: { xs: "120px", md: "140px" },
            p: "10px 15px",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "transform .3s ease-out",
            "&:hover": {
              transform: "scale(1.1)",
              background: "linear-gradient(230deg, #0097A4, #676767 100%)",
            },
          }}
        >
          <Link
            style={{ color: "#fff", textDecoration: "none" }}
            to={"https://tezeract.ai/about-us/?Together"}
          >
            Discover Now
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Projects;
