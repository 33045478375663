import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#079AB5",
    },
  },
  typography: {
    fontFamily: "Outfit",
    // fontSize: "62.5%",
  },
});
