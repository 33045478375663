import { Box } from "@mui/material";
import React from "react";

const MainBox = ({ children }) => {
  return (
    <Box
      sx={{
        height: "100%",
        pb: "20px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#111111",
        borderRadius: "10px",
        position: "relative",
      }}
    >
      {children}
    </Box>
  );
};

export default MainBox;
