import { ArrowForward } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import footer1 from "../../Assets/images/footer1.png";
import footer2 from "../../Assets/images/footer2.png";

const FooterLinks = () => {
  return (
    <Grid
      container
      mt={"2%"}
      spacing={3}
      sx={{ px: { xs: "6px", lg: "15px" } }}
      py={"10px"}
    >
      <Grid
        item
        xs={12}
        sm={6}
        // md={3}
        lg={3}
        sx={{
          color: "#fff",
          "& ul, a ": {
            px: "17px",
            textDecoration: "none",
            color: "#9F9F9F",
          },
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
          Projects
        </Typography>
        <ul style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <li className="footer-list">
            <Link to={"https://tezeract.ai/formole-computer-vision-solution/"}>
              Formole
            </Link>
          </li>
          <li className="footer-list">
            <Link
              to={
                "https://tezeract.ai/peersuma-automated-video-editing-tool-case-study/"
              }
            >
              Peersuma
            </Link>
          </li>
          <li className="footer-list">
            <Link to={"https://tezeract.ai/alisia-ocr-detection-system/"}>
              Alisia
            </Link>
          </li>
        </ul>
        <Box
          sx={{
            backgroundColor: "#1C82953D",
            display: "flex",
            direction: "row",
            alignItems: "center",
            justifyContent: "center",
            // gap: "0px",
            borderRadius: "100px",
            p: "8px 5px",
            fontSize: "13px",
            color: "#45C1CF",
            cursor: "pointer",
            width: "max-content",
            mt: { xs: "0px", md: "25px" },
          }}
        >
          <Link
            to={"https://tezeract.ai/portfolio-ai-projects/"}
            style={{
              color: "#45C1CF",
              paddingRight: "2px",
            }}
          >
            View More
          </Link>
          <ArrowForward sx={{ fontSize: "16px", paddingRight: "8px" }} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        // md={3}
        lg={3}
        sx={{
          color: "#fff",
          "& ul, a ": {
            px: "17px",
            textDecoration: "none",
            color: "#9F9F9F",
          },
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
          Services
        </Typography>
        <ul style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <li className="footer-list">
            <Typography className="text" sx={{ fontSize: "14px" }}>
              Computer Vision
            </Typography>
          </li>
          <li className="footer-list">
            <Typography className="text" sx={{ fontSize: "14px" }}>
              Data Science and Analytics
            </Typography>
          </li>
          <li className="footer-list">
            <Typography className="text" sx={{ fontSize: "14px" }}>
              ML & Predective Analytics
            </Typography>
          </li>
        </ul>
        <Box
          sx={{
            backgroundColor: "#1C82953D",
            display: "flex",
            direction: "row",
            alignItems: "center",
            justifyContent: "center",
            // gap: "0px",
            borderRadius: "100px",
            p: "8px 5px",
            fontSize: "13px",
            color: "#45C1CF",
            cursor: "pointer",
            width: "max-content",
          }}
        >
          <Link
            to={"https://tezeract.ai/tezeracts-ai-solutions/"}
            style={{ color: "#45C1CF", paddingRight: "2px" }}
          >
            View More
          </Link>
          <ArrowForward sx={{ fontSize: "15px", paddingRight: "8px" }} />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        // md={3}
        lg={2}
        sx={{
          color: "#fff",
          "& ul, a ": {
            px: "17px",
            textDecoration: "none",
            color: "#9F9F9F",
          },
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
          Company
        </Typography>
        <ul style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <li className="footer-list">
            <Link to={"https://tezeract.ai/"}>Home</Link>
          </li>
          <li className="footer-list">
            <Link to={"https://tezeract.ai/about-us/"}>About us</Link>
          </li>
          <li className="footer-list">
            <Link to={"https://tezeract.ai/teams-leading-ai-companies/"}>
              Team
            </Link>
          </li>
          <li className="footer-list">
            <Link to={"https://tezeract.ai/tezeracts-blogs/"}>Blog</Link>
          </li>
          <li className="footer-list">
            <Link to={"https://tezeract.ai/contact-tezeract/"}>Contact us</Link>
          </li>
        </ul>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        // md={3}
        lg={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack direction={"row"} gap={"50px"}>
          <Box sx={{ width: { xs: "50%", lg: "120px" } }}>
            <img src={footer1} alt="footerImg" width={"100%"} />
          </Box>
          <Box sx={{ width: { xs: "50%", lg: "120px" } }}>
            <img src={footer2} alt="footerImg" width={"100%"} />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FooterLinks;
