import { ExpandLess } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const ScrollTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    if (window.scrollY > 50) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {isVisible && (
        <Box
          onClick={goToBtn}
          sx={{
            color: "#fff",
            position: "fixed",
            bottom: "2rem",
            right: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#54B3B8",
            borderRadius: "50%",
            height: "38px",
            width: "38px",
            zIndex: 99,
            cursor: "pointer",
          }}
        >
          <ExpandLess />
        </Box>
      )}
    </Box>
  );
};

export default ScrollTopBtn;
