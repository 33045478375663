import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDJDxMZ5si888J2jJFs0lDdZUaXSuieW5E",
  authDomain: "onboardingapp-12504.firebaseapp.com",
  projectId: "onboardingapp-12504",
  storageBucket: "onboardingapp-12504.appspot.com",
  messagingSenderId: "566644137544",
  appId: "1:566644137544:web:eaf7ba47c746642c53427d",
  measurementId: "G-YQTFKNXE2W",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
