import { Box, Typography } from "@mui/material";
import React from "react";
import FormPage from "./FormPage/FormPage";
import Wrapper from "../../components/Wrapper/Wrapper";
import { atom, useAtom } from "jotai";
import Home from "./Home/Home";
import FinalScreen from "./FinalScreen/FinalScreen";
import BusinessInfo from "./Home/BusinessInfo";
export const TrackCurrentPage = atom(1);
const OnBoarding = () => {
  const [currentPage, setcurrentPage] = useAtom(TrackCurrentPage);

  const renderCurrentPage = () => {
    switch (currentPage) {
      case 1:
        return <Home />;
      case 2:
        return <BusinessInfo />;
      case 3:
        return <FormPage />;
      case 4:
        return <FinalScreen />;
      default:
        return null;
    }
  };
  return (
    <Box
      sx={{
        my: "2%",
        px: "20px",
        "& *": {
          fontFamily: "Outfit",
        },
      }}
    >
      <Wrapper>
        <Typography
          sx={{
            fontSize: { xs: "28px", sm: "28px", md: "30px", lg: "40px" },
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            my: "2%",
          }}
        >
          Your Innovative Journey Begins Here!
        </Typography>
        {renderCurrentPage()}
      </Wrapper>
    </Box>
  );
};

export default OnBoarding;
