import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  Divider,
} from "@mui/material";
import image from "../../../Assets/images/artboard-final.webp";
import success from "../../../Assets/images/success.webp";
import { useAtom, atom } from "jotai";
import { useNavigate } from "react-router-dom";
import { userDataAtom } from "../Home/Home";
import FormHeading from "../../../components/common/FormHeading/FormHeading";

export default function FinalScreen() {
  const [userInfo, setUserInfo] = useAtom(userDataAtom);
  // const backendUrl = "http://localhost:3002/sendEmail";
  const backendUrl='https://customer-onboarding.tezeract.ai/backend/sendEmail';

  //Email send to user
  const sendEmailToUser = async () => {
    console.log("Function");
    const bodyToSend = {
      name: userInfo?.homeD,
      email: userInfo?.homeA,
    };
    console.log("bodyToSend", JSON.stringify(bodyToSend));
    try {
      const resp = await fetch(`${backendUrl}`, {
        method: "POST",
        // credentials: "include",
        keepalive: true,
        headers: {
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyToSend),
      }).then((res) => res.json());
      console.log("response", resp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Useffect");
    sendEmailToUser();
  }, []);
  return (
    <>
      <Box
        sx={{
          height: "100%",
          pb: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space=between",
          rowGap: "40px",
          backgroundColor: "#111111",
          borderRadius: "10px",
          //   "@media(max-width:800px)": {
          //     height: "fit-content",
          //     padding: "0px 0 40px 0",
          //   },
        }}
      >
        {/* <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography
            fontWeight={600}
            sx={{
              color: "white",
              fontSize: "40px",
              textAlign: "start",
              margin: "1% 1% 1% 5%",
              "@media(max-width:900px)": { fontSize: "35px" },
              "@media(max-width:800px)": { fontSize: "30px" },
            }}
          >
            Let's get started
          </Typography>
        </Box>{" "} */}
        <Box sx={{ mb: "2%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: "2%",
            }}
          >
            <FormHeading text={"Let's get started"} />
          </Box>
          <Divider sx={{ bgcolor: "#fff", mb: "1%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#252525",
            // height: "80vh",
            width: "90%",
            alignSelf: "center",
            borderRadius: "10px",
            // "@media(max-width:1000px)": { width: "40%" },
            // "@media(max-width:800px)": { width: "60%" },
            // "@media(max-width:600px)": { width: "90%" },
            // "@media(max-width:450px)": { width: "100%" },
          }}
        >
          <Box
            sx={{
              height: {
                xs: "120px",
                sm: "125px",
                md: "130px",
                lg: "150px",
                xl: "170px",
              },
            }}
          >
            <img
              style={{
                objectFit: "fill",
                objectPosition: "center",
                borderRadius: "10px 10px 0px 0",
              }}
              src={image}
              alt={"imageSwap"}
              height={"100%"}
              width={"100%"}
            />
          </Box>
          {/* <img style={{ objectFit: "contain" }} src={image} alt={"imageSwap"} /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              flexWrap: "wrap",
              padding: "2%",
              // bgcolor: "red",
              alignItems: "center",
              rowGap: "20px",
            }}
          >
            <img
              style={{ objectFit: "contain" }}
              src={success}
              alt={"imageSwap"}
            />
            <Typography
              fontSize={"25px"}
              fontWeight={600}
              textAlign={"center"}
              sx={{
                color: "#00BDDF",
                fontWeight: "600",
              }}
            >
              Thank you for your submission!
            </Typography>
            <Typography
              fontWeight={200}
              textAlign={"center"}
              sx={{
                color: "#939393",
                fontSize: "15px",
              }}
            >
              We sincerely appreciate your valuable input. Let’s embark on an
              exciting journey together!
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
