import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import { useAtom, atom } from "jotai";
import { useNavigate } from "react-router-dom";
import InstructionBox from "../InstructionBox/InstructionBox";
import { TrackCurrentQuestionNum } from "../../pages/onBoarding/FormPage/FormPage";
import { userDataAtom } from "../../pages/onBoarding/Home/Home";
import { TrackCurrentPage } from "../../pages/onBoarding";
import TestImage from "../../Assets/images/artboard-1.webp";
import blur1 from "../../Assets/images/Instrution-1.webp";
import blur2 from "../../Assets/images/instruction-2.webp";
import blur3 from "../../Assets/images/instruction-3.webp";
import blur4 from "../../Assets/images/instruction-4.webp";
import blur5 from "../../Assets/images/instruction-5.webp";

export default function FormWithImage({
  mainHeading,
  Question1,
  Question2,
  //   setcurrentQuestion,
  stepNum,
  setcurrentStep,
  artboardImage,
  setuserInfo,
  answer1,
  answer2,
}) {
  const navigate = useNavigate();
  console.log(
    " FormWithImage props",
    mainHeading,
    Question1,
    Question2,
    stepNum
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [userAtom, setuserAtom] = useAtom(userDataAtom);
  console.log("userAtom props", userAtom);
  const [currentQuestion, setcurrentQuestion] = useAtom(
    TrackCurrentQuestionNum
  );
  const [currentHomePage, setcurrentHomePage] = useAtom(TrackCurrentPage);
  const [inputRequiredError, setInputRequiredError] = useState({
    answer1: false,
    answer2: false,
  });
  const [placeholderA, setPlaceholderA] = useState("");
  const [placeholderB, setPlaceholderB] = useState("");
  const [instructionImg, setInstructionImg] = useState();
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);

  const handleFirstInputChange = (event) => {
    const { name, value } = event.target;
    console.log(" handleInputChange", name, value);

    // setInputValue((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));

    if (stepNum === 1) {
      setuserInfo({
        ...userAtom,
        oneA: value,
      });
    }
    if (stepNum === 2) {
      setuserInfo({
        ...userAtom,
        twoA: value,
      });
    }
    if (stepNum === 3) {
      setuserInfo({ ...userAtom, threeA: value });
    }
    if (stepNum === 4) {
      setuserInfo({
        ...userAtom,
        fourA: value,
      });
    }
    if (stepNum === 5) {
      setuserInfo({ ...userAtom, fiveA: value });
    }
  };

  const handleSecondInputChange = (event) => {
    const { name, value } = event.target;
    console.log(" handleInputChange", name, value);
    if (typeof value == "string") {
      console.log("String");
    } else {
      console.log("Number");
    }

    // setInputValue((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));

    if (stepNum === 1) {
      setuserInfo({
        ...userAtom,
        // oneA: value,
        oneB: value,
      });
    }
    if (stepNum === 2) {
      setuserInfo({
        ...userAtom,
        // twoA: value,
        twoB: value,
      });
    }
    if (stepNum === 3) {
      setuserInfo({ ...userAtom, threeB: value });
    }
    if (stepNum === 4) {
      setuserInfo({
        ...userAtom,
        // fourA: value,
        fourB: value,
      });
    }
    if (stepNum === 5) {
      setuserInfo({ ...userAtom, fiveB: value });
    }
  };

  //Updating disable button
  useEffect(() => {
    if (answer1 === "" || answer2 === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [userAtom, stepNum]);

  //Updating placeholder and Instruction image
  useEffect(() => {
    if (stepNum === 1) {
      setPlaceholderA(" Local coffee shops, Parks, Social media groups");
      setPlaceholderB("Google, Professionals platforms, News Websites");
      setInstructionImg(blur1);
    } else if (stepNum === 2) {
      setPlaceholderA(
        "Time consumption in repetitive tasks, product development, and communication"
      );
      setPlaceholderB(
        "Fear of wrong consultation, market competition, public speaking"
      );
      setInstructionImg(blur2);
    } else if (stepNum === 3) {
      setPlaceholderA(
        "Expand globally, build problem-solving products, become an industry expert."
      );
      setPlaceholderB("I mostly preferred to communicate by email.");
      setInstructionImg(blur3);
    } else if (stepNum === 4) {
      setPlaceholderA("I work in soccer coaching in the sports industry.");
      setPlaceholderB(
        "Difficulty in finding quality coaching, and training facilities"
      );
      setInstructionImg(blur4);
    } else {
      setPlaceholderA("Spending time with nature, Connecting with loved ones");
      setPlaceholderB(
        "Workout, creative projects, time with loved ones, playful moments with pets"
      );
      setInstructionImg(blur5);
    }
  }, [stepNum]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "#252525",
        // height: "80vh",
        borderRadius: "10px",
        width: "70%",
        "@media(max-width:900px)": { width: "100%" },
      }}
    >
      <Box
        sx={{
          height: {
            xs: "120px",
            sm: "125px",
            md: "130px",
            lg: "150px",
            xl: "170px",
          },
        }}
      >
        {/* {currentQuestion === 0 ||
        stepNum === 2 ||
        stepNum === 4 ||
        currentQuestion === 5.1 ? (
          <Box sx={{ height: "100%" }}>
            <img
              style={{
                objectFit: "fill",
                backgroundSize: "cover",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
              src={artboardImage}
              alt={"imageSwap"}
              height={"100%"}
              width={"100%"}
            />
          </Box>
        ) : ( */}
        <Box sx={{ height: "100%", width: "100%", borderRadius: "10px" }}>
          <InstructionBox
            // img={currentQuestion === 0 ? artboardImage : instructionImg}
            img={artboardImage}
            stepNum={stepNum}
          />
        </Box>
        {/* )} */}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: "2%",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
            margin: "2%",
          }}
        >
          <Typography
            fontWeight={200}
            textAlign={"start"}
            textTransform={"capitalize"}
            sx={{
              color: "#24B2C5",
              fontWeight: "600",
              fontSize: {
                xs: "16px",
                sm: "18px",
                md: "18px",
                lg: "20px",
                xl: "22px",
              },
              marginLeft: "1%",
            }}
          >
            {mainHeading}
          </Typography>
          <Box sx={{ bgcolor: "#3C3C3C", borderRadius: "10px", padding: "4%" }}>
            <Typography
              fontWeight={200}
              sx={{
                color: "white",
                fontWeight: "400",
                textAlign: "start",
                marginBottom: "1%",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "14px",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              {Question1}
            </Typography>
            <TextField
              multiline={true}
              maxRows={3}
              name="answer1"
              value={answer1}
              onChange={handleFirstInputChange}
              placeholder={placeholderA}
              variant="filled"
              sx={{
                width: "100%",
                textarea: {
                  "::placeholder": { color: "#FFFFFF" },
                  color: "white",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "14px",
                    lg: "16px",
                    xl: "16px",
                  },
                },
              }}
              onFocus={() => {
                if (stepNum === 1) {
                  setcurrentQuestion(1.1);
                } else if (stepNum === 2) {
                  setcurrentQuestion(2.1);
                } else if (stepNum === 3) {
                  setcurrentQuestion(3.1);
                } else if (stepNum === 4) {
                  setcurrentQuestion(4.1);
                } else if (stepNum === 5) {
                  setcurrentQuestion(5.1);
                }
              }}
              FormHelperTextProps={{
                style: {
                  backgroundColor: "transparent",
                  marginLeft: "0rem",
                  marginRight: "0rem",
                  marginTop: "0rem",
                },
              }}
              inputProps={{
                onBlur: () => {
                  setInputRequiredError((prevState) => ({
                    ...prevState,
                    answer1: true,
                  }));
                },
              }}
              helperText={
                inputRequiredError.answer1 && answer1 == ""
                  ? "This field is required"
                  : ""
              }
              error={inputRequiredError.answer1 && answer1 == "" ? true : false}
              ref={input1Ref}
            />
          </Box>
          {stepNum != 6 && (
            <Box
              sx={{ bgcolor: "#3C3C3C", borderRadius: "10px", padding: "4%" }}
            >
              <Typography
                fontWeight={200}
                sx={{
                  color: "white",
                  fontWeight: "400",
                  textAlign: "start",
                  marginBottom: "1%",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "14px",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                {Question2}
              </Typography>
              <TextField
                multiline={true}
                maxRows={3}
                value={answer2}
                name="answer2"
                id="filled-basic-2"
                //   label="Filled"
                placeholder={placeholderB}
                onChange={handleSecondInputChange}
                variant="filled"
                sx={{
                  width: "100%",
                  textarea: {
                    "::placeholder": { color: "#FFFFFF" },
                    color: "white",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                  },
                }}
                onFocus={() => {
                  if (stepNum === 1) {
                    setcurrentQuestion(1.2);
                  } else if (stepNum === 2) {
                    setcurrentQuestion(2.2);
                  } else if (stepNum === 3) {
                    setcurrentQuestion(3.2);
                  } else if (stepNum === 4) {
                    setcurrentQuestion(4.2);
                  } else if (stepNum === 5) {
                    setcurrentQuestion(5.2);
                  }
                }}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: "transparent",
                    marginLeft: "0rem",
                    marginRight: "0rem",
                    marginTop: "0rem",
                  },
                }}
                inputProps={{
                  onBlur: () => {
                    setInputRequiredError((prevState) => ({
                      ...prevState,
                      answer2: true,
                    }));
                  },
                }}
                helperText={
                  inputRequiredError.answer2 && answer2 == ""
                    ? "This field is required"
                    : ""
                }
                error={
                  inputRequiredError.answer2 && answer2 == "" ? true : false
                }
                ref={input2Ref}
              />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              columnGap: { xs: "10px", md: "30px" },
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "flex-end",
                bgcolor: "transparent",
                "&:hover": { bgcolor: "transparent" },
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "16px",
                },
              }}
              onClick={() => {
                {
                  if (stepNum == 1) {
                    setcurrentHomePage((step) => step - 1);
                  } else setcurrentStep((step) => step - 1);
                }
                setcurrentQuestion(0);
              }}
            >
              Previous
            </Button>
            <div
              style={{
                width: "0.1%",
                height: "100%",
                backgroundColor: "white",
              }}
            ></div>
            <Button
              onClick={() => {
                if (answer1 === "") {
                  input1Ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                  });
                } else if (answer2 === "") {
                  input2Ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                  });
                } else {
                  input3Ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                  });
                }
                if (stepNum != 6) {
                  console.log("not step 5", stepNum);
                  if (!answer1 || !answer2) {
                    console.log("true", answer1, answer2);
                    setInputRequiredError({ answer1: true, answer2: true });
                  } else if (answer1 && answer2) {
                    setcurrentStep((step) => step + 1);
                    setInputRequiredError({ answer1: false, answer2: false });
                  }
                }
                //  else if (stepNum == 5) {
                //   if (!answer1) {
                //     setInputRequiredError({ answer1: true });
                //   } else if (answer1) {
                //     setcurrentStep((step) => step + 1);
                //     setInputRequiredError({ answer1: false, answer2: false });
                //   }
                // }
                setcurrentQuestion(0);
                // handleDone();
              }}
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "flex-end",

                bgcolor: isDisabled ? "#5A5A5A" : "#079AB5",
                "&:hover": { bgcolor: isDisabled ? "#5A5A5A" : "#079AB5" },
                "&.Mui-disabled": {
                  background: "#5A5A5A",
                  color: "#ffff",
                  cursor: "not-allowed",
                },
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "16px",
                },
              }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
