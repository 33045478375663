import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import { Collapse } from "react-collapse";

export default function StepperHeading({
  mainHeading,
  subHeading,
  stepNumber,
  activeSubItemStatus,
  activeStepStatus,
}) {
  const [first, setfirst] = useState(false);
  return (
    <>
      <Box
        onClick={() => setfirst(!first)}
        sx={{
          width: "100%",
          // height: "9%",
          height: "44px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "start",
          textTransform: "capitalize",
          fontSize: {
            xs: "12px",
            sm: "14px",
            md: "14px",
            lg: "16px",
            xl: "17px",
          },
        }}
      >
        <div style={{ color: "white", fontWeight: "600" }}>{mainHeading}</div>
      </Box>
      <Collapse isOpened={activeStepStatus === "activeStep" ? true : false}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            textAlign: "start",
            height: "80px",
            // rowGap: { md: "15px", sm: "30px", lg: "20px" },
            gap: { md: "10px", sm: "10px", lg: "10px" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              // height: "10%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              fontSize: {
                xs: "11px",
                sm: "13px",
                md: "14px",
                lg: "16px",
                xl: "18px",
              },
            }}
          >
            <div
              style={{
                color:
                  activeSubItemStatus === "activeFirstSubItem"
                    ? "white"
                    : "#797979",
                fontWeight: "600",
              }}
            >
              {subHeading[0]}
            </div>
          </Box>

          <Box
            sx={{
              width: "100%",
              // height: "10%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              fontSize: {
                xs: "11px",
                sm: "13px",
                md: "14px",
                lg: "16px",
                xl: "18px",
              },
            }}
          >
            <div
              style={{
                color:
                  activeSubItemStatus === "activeSecondSubItem"
                    ? "white"
                    : "#797979",
                fontWeight: "600",
              }}
            >
              {subHeading[1]}
            </div>
          </Box>
        </Box>
      </Collapse>
    </>
  );
}
