import { Box } from "@mui/material";
import React from "react";

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        maxWidth: { md: "1000px", lg: "1140px", xl: "1220px" },
        // maxWidth: "1240px",
        m: "0px auto",
        width: "100%",
        // px: "10px",
      }}
    >
      {children}
    </Box>
  );
};

export default Wrapper;
